import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~5,[2],[3]],
		"/(auth)/after-signup": [~100],
		"/(onboarding)/company": [~110,[4]],
		"/(app)/dashboard/business-analyst": [6,[2],[3]],
		"/(app)/dashboard/finance-ratio": [7,[2],[3]],
		"/(app)/dashboard/sales": [8,[2],[3]],
		"/(app)/finance/account-payables": [9,[2],[3]],
		"/(app)/finance/account-payables/detail": [~10,[2],[3]],
		"/(app)/finance/balance-sheets": [~11,[2],[3]],
		"/(app)/finance/capital-flow": [~12,[2],[3]],
		"/(app)/finance/cash-flow": [~13,[2],[3]],
		"/(app)/finance/close-accounting-period": [~14,[2],[3]],
		"/(app)/finance/income-statement": [~15,[2],[3]],
		"/(app)/finance/income-statement/show": [~16,[2],[3]],
		"/(app)/finance/journals": [~17,[2],[3]],
		"/(app)/finance/journals/add": [~18,[2],[3]],
		"/(app)/finance/journals/detail": [~19,[2],[3]],
		"/(app)/finance/journals/edit/[id]": [~20,[2],[3]],
		"/(app)/finance/ledgers": [~21,[2],[3]],
		"/(app)/finance/payable-payments": [~22,[2],[3]],
		"/(app)/finance/payable-payments/add": [~23,[2],[3]],
		"/(app)/finance/payable-payments/detail": [~24,[2],[3]],
		"/(app)/finance/payable-payments/edit": [~25,[2],[3]],
		"/(app)/finance/payable-payments/journal-voucher": [~26,[2],[3]],
		"/(app)/finance/payment-invoices": [~27,[2],[3]],
		"/(app)/finance/payment-invoices/add": [~28,[2],[3]],
		"/(app)/finance/payment-invoices/detail": [~29,[2],[3]],
		"/(app)/finance/payment-invoices/edit": [~30,[2],[3]],
		"/(app)/finance/payment-invoices/journal-voucher": [~31,[2],[3]],
		"/(app)/finance/purchase-invoice-returns": [~32,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/add": [~33,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/detail": [~34,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/edit": [35,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/journal-voucher": [~36,[2],[3]],
		"/(app)/finance/purchase-invoices": [37,[2],[3]],
		"/(app)/finance/purchase-invoices/add-payment": [~39,[2],[3]],
		"/(app)/finance/purchase-invoices/add": [~38,[2],[3]],
		"/(app)/finance/purchase-invoices/detail": [~40,[2],[3]],
		"/(app)/finance/purchase-invoices/edit": [41,[2],[3]],
		"/(app)/finance/purchase-invoices/journal-voucher": [~42,[2],[3]],
		"/(app)/finance/receivable-payments": [43,[2],[3]],
		"/(app)/finance/receivable-payments/[id]": [44,[2],[3]],
		"/(app)/finance/sale-invoices": [~45,[2],[3]],
		"/(app)/finance/sale-invoices/[id]": [~46,[2],[3]],
		"/(app)/finance/sale-invoices/[id]/edit": [~47,[2],[3]],
		"/(app)/finance/settings/account-classifications": [~48,[2],[3]],
		"/(app)/finance/settings/accounts-kpi": [~51,[2],[3]],
		"/(app)/finance/settings/accounts": [49,[2],[3]],
		"/(app)/finance/settings/accounts/balance": [50,[2],[3]],
		"/(app)/finance/settings/banks": [~52,[2],[3]],
		"/(app)/finance/settings/general-settings": [~53,[2],[3]],
		"/(app)/finance/settings/suppliers": [~54,[2],[3]],
		"/(app)/finance/vendor-debit-refunds": [~55,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/account-balance-mutation": [~56,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/add-pay-other-invoice": [~57,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/detail": [~58,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/edit": [~59,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/journal-voucher": [~60,[2],[3]],
		"/(auth)/forget-password": [101],
		"/(app)/integration/channel-settings": [~61,[2],[3]],
		"/(app)/integration/facebook-ads": [62,[2],[3]],
		"/(app)/integration/google-ads": [63,[2],[3]],
		"/(app)/integration/manual-ads-payment": [64,[2],[3]],
		"/(app)/integration/marketplace": [~65,[2],[3]],
		"/(app)/integration/pos": [~66,[2],[3]],
		"/(app)/integration/tiktok-ads": [67,[2],[3]],
		"/(app)/integration/whatsapp": [~68,[2],[3]],
		"/(app)/inventory/categories": [69,[2],[3]],
		"/(app)/inventory/products": [~70,[2],[3]],
		"/(app)/inventory/products/[id]": [~71,[2],[3]],
		"/(app)/inventory/reports/product-age": [~72,[2],[3]],
		"/(app)/inventory/stock-opname": [73,[2],[3]],
		"/(app)/inventory/stock-opname/create": [~76,[2],[3]],
		"/(app)/inventory/stock-opname/[id]": [~74,[2],[3]],
		"/(app)/inventory/stock-opname/[id]/edit": [~75,[2],[3]],
		"/(app)/inventory/warehouses": [77,[2],[3]],
		"/(auth)/login": [102],
		"/(auth)/logout": [~103],
		"/(app)/marketing/crm": [78,[2],[3]],
		"/(app)/marketing/kol": [79,[2],[3]],
		"/(onboarding)/payment/error": [111,[4]],
		"/(onboarding)/payment/finish": [~112,[4]],
		"/(onboarding)/payment/unfinish": [113,[4]],
		"/(onboarding)/pricing": [~114,[4]],
		"/(auth)/register-verification-check": [~106],
		"/(auth)/register-verification": [105],
		"/(auth)/register": [~104],
		"/(app)/report/ads": [80,[2],[3]],
		"/(app)/report/marketplace": [81,[2],[3]],
		"/(app)/report/sales": [~82,[2],[3]],
		"/(auth)/resend-verification": [~107],
		"/(auth)/role": [108],
		"/(app)/sales": [~83,[2],[3]],
		"/(app)/sales/channels": [86,[2],[3]],
		"/(app)/sales/customers": [87,[2],[3]],
		"/(app)/sales/entry": [~88,[2],[3]],
		"/(app)/sales/payment-methods": [~89,[2],[3]],
		"/(app)/sales/print/orders": [~90,[2],[3]],
		"/(app)/sales/print/receipts": [~91,[2],[3]],
		"/(app)/sales/receipt/[id]": [~92,[2],[3]],
		"/(app)/sales/shippings": [93,[2],[3]],
		"/(app)/sales/tax-settings": [94,[2],[3]],
		"/(app)/sales/upload": [95,[2],[3]],
		"/(app)/sales/[id]": [~84,[2],[3]],
		"/(app)/sales/[id]/edit": [~85,[2],[3]],
		"/(app)/settings/company-profiles": [~96,[2],[3]],
		"/(app)/settings/employees": [~97,[2],[3]],
		"/(app)/settings/profiles": [~98,[2],[3]],
		"/(app)/tutorial": [99,[2],[3]],
		"/(auth)/users/invitee-verification-check": [~109]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';